import * as React from "react"
import { graphql } from "gatsby";
import PageBuilder from "../components/page-builder";

export const query = graphql`
  query HomePageQuery {
    fiveoakcms {
      homepage {
        id
        title
        published_at
        includefooter
        headertype
        meta {
          ...PageMetaContent
          ...PageScriptContent
          ...PageRawjsContent
        }
        content {
          ...LandingHeroContent
          ...LandingLogosContent
          ...LandingContentimageContent
          ...LandingStepslistContent
          ...LandingVideofooterContent
          ...LandingSuccessstoriesContent
          ...LandingSocialproofContent
          ...LandingRawhtmlContent
          ...LandingHeronearmeContent
          ...LandingFinalctaContent
          ...LandingVideoContent
          ...LandingCarouselContent
          ...LandingAppintegrationContent
          ...LandingFaqContent
        }
      }
    }
  }
`;

// markup
const IndexPage = ({ data }) => {
  const homePage = data.fiveoakcms.homepage;

  return (
    <PageBuilder pageData={homePage} />
  );
}

export default IndexPage
